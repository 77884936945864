body {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',*/
/*    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
    background: #eceff180;
  height: 100%;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

/*если нужно скрыть элемент например выгрузку файлов*/
.hidden {
  opacity: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
}


/* для просмотра чеков */
/*body {*/
/*  background-color: #505050;*/
/*}*/

.receipt {
  text-align: center;
  padding: 50px;
  /*overflowX: 'auto'*/
}

.receipt-zigzag {
  display: inline-block;
}

.receipt-zigzag:before {
  content: " ";
  display: block;
  height: 8px;
  background: transparent url(assets/img/zigzag-top.png) repeat-x;
}

.receipt-zigzag:after {
  content: " ";
  display: block;
  height: 8px;
  background: transparent url(assets/img/zigzag-bottom.png) repeat-x;
}

.receipt-content {
  display: inline-block;
  background-color: white;
  min-width: 250px; /* Так как ширина зависит от контента, нужно ограничить минимальную ширину ленты */
  min-height: 250px;
  max-width: 450px; /* Это защита от касячных чеков, у которых в одну строчку вывелось несколько атрибутов, чтобы они не выглядели напечатанными на очень широкой бумаге */
}

.receipt-text {
  display: inline-block;
  text-align: left;
  white-space: pre-wrap;
  font-family: monospace;
  padding: 25px;
}

.receipt-image {
  display: block;
  margin: 0 auto;
}

.eslip-notfound {
  padding-top: 100px;
}
